import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Instructions from "../components/instructions.component";
import * as XLSX from "xlsx";
import { PROPERTIES, PROPERTY, USER } from "../constants.ts";

function HomePage() {
  const [error, setError] = useState(false);
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    unit: "",
    lastName: "",
    property: PROPERTY.AV,
  });
  const [jsonData, setJsonData] = useState<USER[]>();
  const [cashPayId, setCashPayId] = useState("");

  useEffect(() => {
    const parseExcelFile = async () => {
      try {
        const response = await fetch("/paylease-ids.xlsx");
        const arrayBuffer = await response.arrayBuffer();
        const workbook = XLSX.read(new Uint8Array(arrayBuffer), {
          type: "array",
        });
        const sheetName = workbook.SheetNames[0];
        const sheetData: USER[] = XLSX.utils.sheet_to_json(
          workbook.Sheets[sheetName]
        );
        setJsonData(sheetData);
      } catch (err) {
        console.error("Error parsing Excel file: ", err);
      }
    };

    parseExcelFile();
  }, []);

  const retrieveIfExists = (
    unit: string,
    lastName: string,
    property: PROPERTY
  ) => {
    let id = "";
    const lName = lastName.toLowerCase().replace(" ", "");
    jsonData?.forEach((user: USER) => {
      if (
        user.last_name &&
        user.last_name.toLowerCase().replace(" ", "") === lName &&
        user.unit.toString().toUpperCase().replace(" ", "") ===
          unit.toUpperCase().replace(" ", "") &&
        user.property == property
      )
        if (user.cashId !== undefined && user.cashId !== "") id = user.cashId;
    });
    return id;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const cashId = retrieveIfExists(
      formData.unit,
      formData.lastName,
      formData.property
    );

    if (cashId === "") setError(true);
    else {
      setError(false);
      setCashPayId(cashId);
    }
  };

  return (
    <div className="flex flex-col w-full lg:flex-row bg-white">
      <div className="flex justify-center  lg:mb-4 lg:mt-4  w-full">
        <div className="bg-white text-black p-12 xl:rounded-lg shadow-xl xl:w-4/5">
          <h1 className=" text-xl lg:text-4xl font-bold text-misOrange">
            {t("cashPayId")}
          </h1>
          <div className="mb-4 lg:mb-12 mt-2 font-normal text-xs lg:text-sm">
            {t("sublabel")}
          </div>

          {cashPayId !== "" ? (
            <h2 className="text-green-600 font-bold text-lg lg:text-3xl pb-8">
              YOUR CASH PAY ID: {cashPayId}
            </h2>
          ) : null}

          <form onSubmit={handleSubmit}>
            <div className="text-sm text-black  lg:text-lg font-semibold ">
              <div className="flex flex-col lg:flex-row w-full">
                <div className="mb-4">
                  <div className="flex flex-col ">
                    <label className="pb-2">{t("unitLabel")}</label>
                    <input
                      className="bg-gray-100 p-2 rounded-md"
                      type="text"
                      value={formData.unit}
                      onChange={handleChange}
                      name="unit"
                    ></input>
                  </div>
                </div>

                <div className="mb-4 lg:pl-4 flex-col lg:flex-row  w-full">
                  <div className="flex flex-col ">
                    <label className="pb-2">{t("lastNameLabel")}</label>
                    <input
                      className="bg-gray-100 p-2 rounded-md"
                      type="text"
                      value={formData.lastName}
                      onChange={handleChange}
                      name="lastName"
                    ></input>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <label className="block mb-2">{t("propertyLabel")}</label>
                <select
                  name="property"
                  value={formData.property}
                  onChange={handleChange}
                  className="w-full bg-gray-200 p-2 border border-gray-300 rounded"
                >
                  <option value="">{t("selectProperty")}</option>
                  {PROPERTIES.map((val) => (
                    <option value={val.value}>{val.label}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mt-5 text-xl">
              <button
                type="submit"
                className="bg-misBlue text-white w-full text-bold py-2  hover:bg-misOrange"
              >
                {t("submitButton")}
              </button>
            </div>
          </form>
          {error ? (
            <h2 className="text-red-600 font-bold  text-sm lg:text-xl pt-4">
              {t("noCashIdError")}
            </h2>
          ) : null}
        </div>
      </div>
      <div className="flex w-full h-screen">
        <Instructions />
      </div>
    </div>
  );
}

export default HomePage;
