import "./App.css";
import Header from "./components/Header";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import HomePage from "./pages/homepage.tsx";
import i18n from "./i18n"; // Your i18n configuration
import { I18nextProvider } from "react-i18next"; // Import I18nextProvider
localStorage.theme = "light";

function App() {
  return (
    <>
      <I18nextProvider i18n={i18n}>
        <Header />
        <HomePage />
      </I18nextProvider>
    </>
  );
}

export default App;
