import React, { useState } from "react";
import Header from "../components/Header";
import { useTranslation } from "react-i18next";

export const WorkOrderForm: React.FC = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    property: "",
    unitNumber: "",
    email: "",
    phone: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Add your form submission logic here
    console.log(formData);
  };

  return (
    <>
      <div className="w-screen">
        <Header />
        <div className="flex flex-col items-center mt-20  h-screen">
          <h2 className="text-3xl mb-4 font-bold">{t("WO_header")}</h2>

          <form
            className="w-8/12 sm:w-3/4 lg:w-2/3 xl:w-1/5"
            onSubmit={handleSubmit}
          >
            <div className="mb-4">
              <label htmlFor="name" className="block font-medium">
                {t("WO_nameLabel")}
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="w-full border p-2 rounded"
              />
            </div>

            <div className="flex flex-wrap -mx-2 mb-4">
              <div className="w-full sm:w-1/2 px-2">
                <label htmlFor="property" className="block font-medium">
                  {t("WO_propertyLabel")}
                </label>
                <select
                  id="property"
                  name="property"
                  value={formData.property}
                  onChange={handleChange}
                  required
                  className="w-full border p-2 rounded"
                >
                  {/* Populate the options for the property dropdown */}
                  <option value="property1">Property 1</option>
                  <option value="property2">Property 2</option>
                  {/* Add more options as needed */}
                </select>
              </div>

              <div className="w-full sm:w-1/2 px-2">
                <label htmlFor="unitNumber" className="block font-medium">
                  {t("WO_unitNumberLabel")}
                </label>
                <input
                  type="text"
                  id="unitNumber"
                  name="unitNumber"
                  value={formData.unitNumber}
                  onChange={handleChange}
                  className="w-full border p-2 rounded"
                />
              </div>
            </div>

            <div className="mb-4">
              <label htmlFor="email" className="block font-medium">
                {t("WO_emailLabel")}
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full border p-2 rounded"
              />
            </div>

            <div className="mb-4">
              <label htmlFor="phone" className="block font-medium">
                {t("WO_phoneLabel")}
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="w-full border p-2 rounded"
              />
            </div>

            <div className="mb-4">
              <button
                type="submit"
                className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
              >
                {t("WO_submitButtonLabel")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
