import React from "react";
import { Trans } from "react-i18next";
import avalonPic from "../assets/avalonpic.png";

const PaymentInstructions = () => {
  return (
    <div>
      <div className="h-2 bg-misOrange"> </div>
      <div className="grid justify-start">
        <div className="grid col-span-1 text-left text-white p-12  bg-misBlue">
          <div className="space-y-12 text-sm lg:text-lg">
            <p>
              <Trans i18nKey="step1">
                <b>STEP 1:</b>
                <a
                  href="https://www.paylease.com/cashpay/locations"
                  className="text-misOrange font-bold underline"
                >
                  paylease.com/cashpay/locations
                </a>{" "}
                and add your zip code. It will give you the nearest location
                where you can pay through cash.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="step2">
                <b>STEP 2:</b>
                <span className="text-misOrange font-bold">CASH PAY ID</span> by
                filling out the form on this page
              </Trans>
            </p>
            <p>
              <Trans i18nKey="step3">
                <b>STEP 3:</b> - Visit the nearby location which includes major
                retailers like Walmart, Kmart, Ace Cash Express and more! Ask
                for Checkfree Agent at the counter, provide your Unique Cash Pay
                ID and inform the agent you are making a Bill Payment to pay
                Lease community Payments.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="step4">
                <b>STEP 4:</b>
                <span className="text-misOrange font-bold">receipt!</span>
              </Trans>
            </p>
          </div>
          <p className="mt-4 text-sm">
            <Trans i18nKey="note">
              Please note that a $4 convenience fee applies to payments made
              with cash. Zego CheckFree Pay is Cheaper than Paying through
              Regular Money Orders.
            </Trans>
          </p>
        </div>
        <div className="grid col-span-1">
          <img src={avalonPic} alt="Avalon" />
        </div>
      </div>
    </div>
  );
};

export default PaymentInstructions;
