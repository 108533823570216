import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/marsLogo.png";
import { useTranslation } from "react-i18next";
import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";

function Header() {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState("en");

  const handleLanguageChange = (selectedLanguage) => {
    setLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage.code);
  };

  const langs = [
    { id: 1, lang: "English", code: "en" },
    { id: 2, lang: "Español", code: "es" },
  ];

  const [selectedLang, setSelectedLang] = useState(langs[0]);

  return (
    <div className="bg-misBlue p-4 md:p-6 text-base md:text-lg">
      <div className="flex justify-between items-center">
        <Link to="/">
          <img src={logo} width={140} alt="Logo" />
        </Link>

        <Link to="/workorders">Work Orders</Link>
        <div className="font-bold">
          <div className="w-44 md:w-72">
            <Listbox
              value={selectedLang}
              onChange={(selectedLanguage) => {
                setSelectedLang(selectedLanguage);
                handleLanguageChange(selectedLanguage);
              }}
            >
              <div className="mt-1">
                <Listbox.Button className="relative w-44 md:w-full cursor-default rounded-lg text-black bg-white py-2 pl-3 pr-6 md:pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                  <span className="block truncate">{selectedLang.lang}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    &#9660;
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute mt-2 w-44 md:w-72 max-h-44 md:max-h-60 overflow-auto rounded-md bg-white py-1 text-sm md:text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                    {langs.map((lang) => (
                      <Listbox.Option
                        key={lang.code}
                        value={lang}
                        className={({ active, selected }) =>
                          `relative cursor-default select-none py-2 pl-6 md:pl-10 pr-4 ${
                            active
                              ? "bg-amber-100 text-amber-900"
                              : "text-gray-900"
                          } ${selected ? "font-medium" : "font-normal"}`
                        }
                      >
                        {({ selected }) => (
                          <>
                            <span className="block truncate">{lang.lang}</span>
                            {selected && (
                              <span className="text-amber-600">&#10003;</span>
                            )}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
